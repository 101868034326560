import React from 'react'
import { Link } from 'react-router-dom'
const OurLeadership = () => {
  return (
    
      <div>
        <div className="mt-0">
          <div class="w-80 h-50 mx-auto mt-0 ">
            <div className="row d-flex justify-content-end">
              <div className="col-lg-3 pt-12 mt-10">
                <h1 class="mt-0 mb-1 d-md-block d-none source-serif-4">
                  Kesavulu Arthala
                </h1>
                <h4 class="mb-0 text-lg font-weight-bolder text-info text-gradient text-uppercase">
                  Founder & CEO
                </h4>
              </div>
              <div className="col-lg-7 justify-content-end text-end">
                <div class="d-block blur-shadow-image">
                  <img
                    src="../../assets/img/k71.jpg"
                    alt="img-blur-shadow-blog-2"
                    class="img-full "
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-white py-6">
          <h2 className="text-dark source-serif-4 text-normal text-center">
            From Rags to Riches: The Inspirational Journey of Kesavulu A, Founder
            of K7Infotech
          </h2>
          <div className="row container justify-content-between mx-auto d-flex py-6">
            <div className="col-lg-6">
              <div>
                <h4 className="text-normal fw-bolder">Introduction</h4>
                <p>
                K7Infotech, a rising star in the software industry, owes its
                  success to the vision and leadership of its founder, Kesavulu
                  Arthala . An innovator and entrepreneur at heart,Kesavulu
                  Arthala has played a pivotal role in steering K7Infotech from a
                  fledgling startup to a significant player in the tech landscape.
                </p>
              </div>
              <div>
                <h4 className="text-normal fw-bolder">
                  Early Life and Education
                </h4>
                <p>
                  Born and raised in Vejjupalli , Kesavulu Arthala exhibited an
                  early interest in technology and innovation. Their passion for
                  software development blossomed during their high school years,
                  where they often spent hours tinkering with computers and
                  writing code. This early exposure laid a strong foundation for
                  their future endeavors.
                </p>
                <p>
                  Kesavulu Arthala pursued higher education at JNTU, where they
                  earned a degree in Computer Science During their time at
                  university, they participated in various tech competitions,
                  further honing their skills and expanding their knowledge base.
                </p>
              </div>
              <div className="mt-4">
                <h4 className="text-normal fw-bolder">Professional Journey</h4>
                <p>
                  After graduating,Kesavulu Arthala worked at several tech
                  companies, gaining valuable industry experience. Their roles
                  ranged from software developer to project manager, allowing them
                  to acquire a comprehensive understanding of the software
                  development lifecycle and the nuances of managing tech projects.
                </p>
              </div>
              <div className="mt-4">
                <h4 className="text-normal fw-bolder">Founding K7Infotech</h4>
                <p>
                  In 2012, driven by a desire to create innovative software
                  solutions and address unmet needs in the market,Kesavulu Arthala
                  founded K7Infotech. The company's mission was clear: to develop
                  cutting-edge software that simplifies complex processes and
                  enhances user experiences.
                </p>
                <p>
                  Starting with a small team of like-minded professionals,
                  Kesavulu Arthala focused on building a company culture that
                  emphasized creativity, collaboration, and customer-centricity.
                  This approach quickly paid off as K7Infotech's products began to
                  gain traction in the market.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mt-4">
                <h4 className="text-normal fw-bolder">Key Achievements</h4>
                <h6 className="text-black">
                  Under Kesavulu Arthala 's leadership, K7Infotech has achieved
                  several milestones:
                </h6>
                <div>
                  <b className="text-black">Product Innovation :</b>
                </div>
                <p>
                K7Infotech launched several groundbreaking software products that
                  have been widely adopted across various industries.
                </p>
                <div>
                  <b className="text-black">Market Expansion :</b>
                </div>
                <p>
                  The company expanded its presence globally, establishing offices
                  in key regions and building a diverse client base.{" "}
                </p>
                <div>
                  <b className="text-black">Industry Recognition :</b>
                </div>
                <p>
                K7Infotech has received numerous awards and accolades for its
                  innovative solutions and exceptional customer service.{" "}
                </p>
                <div>
                  <b className="text-black">Sustainable Growth :</b>
                </div>
                <p>
                  The company has maintained steady growth, both in terms of
                  revenue and workforce, reflecting its strong market position and
                  strategic vision.{" "}
                </p>
              </div>
  
              <div className="mt-4">
                <h4 className="text-normal fw-bolder">Leadership Style</h4>
                <p>
                  Kesavulu Arthala is known for their visionary leadership and
                  hands-on approach. They believe in empowering their team
                  members, fostering an environment of continuous learning and
                  innovation. Their open-door policy and emphasis on transparent
                  communication have cultivated a positive and productive
                  workplace culture.
                </p>
              </div>
              <div className="mt-4">
                <h4 className="text-normal fw-bolder">Future Vision</h4>
                <p>
                  Looking ahead, Kesavulu Arthala envisions K7Infotech continuing to
                  push the boundaries of technology. Their focus remains on
                  delivering high-quality, innovative software solutions that
                  address evolving market demands and enhance user experiences.{" "}
                </p>
              </div>
              <div className="mt-4">
                <h4 className="text-normal fw-bolder">Conclusion</h4>
                <p>
                  Kesavulu Arthala 's journey from a tech enthusiast to the
                  founder of K7Infotech is a testament to their dedication, hard
                  work, and visionary thinking. Their leadership has not only
                  propelled K7Infotech to new heights but also set a benchmark for
                  innovation and excellence in the software industry.{" "}
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default OurLeadership